@import '../vendors/bootstrap/functions';

@import '../variables';

@import '../vendors/bootstrap/mixins';

.cms-noroute-index {
  display: block;

  .page-main {
    max-width: 1366px;
    padding: 0;
  }
}

.overlay-404 {
  position: relative;
  min-height: 320px;
  background-color: $black;
  text-align: center;
  color: $white;

  img {
    opacity: 0.31;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay-content {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 2rem;

    h1 {
      margin-bottom: 2rem;
      font-weight: 700;
      line-height: 1;
      font-size: 4.5rem;

      @include media-breakpoint-up(md) {
        font-size: 10.2rem;
      }

      em {
        font-style: normal;
        color: $primary;
      }
    }

    p:not(.lead) {
      font-weight: 700;
      font-size: 1.6rem;
      color: $primary;
    }

    .lead {
      font-family: $headings-font-family;
      font-weight: 700;
      font-size: 2.5rem;
    }

    .search-icon {
      right: 3rem;
    }
  }
}
